import { template as template_f64fb2873cb54a8a9f658ed2b0452fc6 } from "@ember/template-compiler";
const WelcomeHeader = template_f64fb2873cb54a8a9f658ed2b0452fc6(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
