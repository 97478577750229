import { template as template_1422b68c1e8c4787b3c40bb6b6f1b1ed } from "@ember/template-compiler";
const FKLabel = template_1422b68c1e8c4787b3c40bb6b6f1b1ed(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
