import { template as template_ad7a96ad706649faad9f02ab6554aff4 } from "@ember/template-compiler";
const SidebarSectionMessage = template_ad7a96ad706649faad9f02ab6554aff4(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
