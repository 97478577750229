import { template as template_fe1769f0136b4eb887f0df19ca781972 } from "@ember/template-compiler";
const FKControlMenuContainer = template_fe1769f0136b4eb887f0df19ca781972(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
